<template>
  <div class="VIPclass">
    <img class="tui-img" v-if="imgList.img" :src="imgList.img" alt="" />
    <div class="kmain">
      <!-- 搜索 -->
      <kind-choose-view :is-jinpin="false" :showSchool="true" :showKc="false" :showZy="false" :type="null"
        :ifImg="false" @isOk="isOk" />
      <!-- 套餐列表 -->
      <div class="contentWrap">
        <div class="contentItem" v-for="(item, index) in exclusiveClassList" :key="index" @click="goDetails(item)">
          <div class="imgItem">
            <img :src="item.img" class="img" />
            <div v-if="item.productLine === '5'" class="oneToOne"></div>
            <div v-else class="imgTips">VIP专属</div>
          </div>
          <div class="titleItem">
            <span v-if="item.isHavePrivateClass == 1" class="xbk"> <img src="~@/assets/img/typeclass/z1.png" alt="">
            </span>
            {{ item.tiitle }}
          </div>

          <div class="bottomTips">
            <div class="topTips">
              <span class="tipsFirst">{{ item.productLine === '5' ? '#1对1直播 · 专属教务' : '#专属服务 · 极速答疑 · 督学跟踪' }}
                <span v-if="item.productLine !== '5'" class="courseNum"> | {{ item.kechengNum ? item.kechengNum + '门课程'
                  :
                  '暂无课程' }}</span></span>
            </div>
          </div>
          <div class="priceItem">
            <div class="symbolOrprice">
              <span class="symbol">{{ '￥' }}</span>
              <span class="price">{{ item.price }}</span>
            </div>
            <div class="more dis_flex">
              <!-- <el-image c src="@/assets/img/Home/Frame.png" fit="contain"> </el-image> -->
              <img class="img" src="../../assets/img/Home/Frame.png" />
              {{ item.clicks ? item.clicks : 0 }}人气值
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageNum" :page-sizes="[12, 24, 36, 48]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import KindChooseView from '../../components/kindChooseViewkvip.vue'
import { selectPageListToIndex } from '@/api/home'
import { selectKindAllCollage, getSurfaceList } from '@/api/home'
import { getDomain } from '@/api/cookies'
export default {
  components: {
    KindChooseView,
  },
  data() {
    return {
      exclusiveClassList: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      search: {},
      imgList: {},
      domain: null,
      loading: null,
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.domain = getDomain()
    this.selectPageListToIndex()
    getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0]
        } else {
          this.imgList = {}
        }
      }
    })
  },
  methods: {
    /* 筛选 */
    isOk(val) {
      this.pageNum = 1
      this.search = val
      this.selectPageListToIndex()
    },
    /* VIP专属班列表 */
    selectPageListToIndex() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      selectPageListToIndex(
        this.pageSize,
        this.pageNum,
        this.search.school,
        this.search.kind,
        this.search.zyId,
        undefined,
        3, /* productLine 3专属线 */
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {
        this.exclusiveClassList = res.rows
        this.total = res.total
        this.loading.close()
      }).catch(() => {
        this.loading.close()
      })
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      let routeUrl = ''
      if (item.productLine === '5') {
        routeUrl = this.$router.resolve({
          path: '/VIP/oneToOneClass',
          query: {
            id: item.id,
          },
        })
      } else {
        routeUrl = this.$router.resolve({
          path: '/VIP/VIPClassxq',
          query: {
            id: item.id,
          }
        })
      }
      window.open(routeUrl.href, '_blank')
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.selectPageListToIndex()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.pageNum = val
      this.selectPageListToIndex()
    },
  },
}
</script>

<style lang="less" scoped>
.tui-img {
  margin: 0;
  width: 100%;
}

/deep/ .kmain {
  width: 1220px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 24px auto;
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.VIPclass {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .contentWrap {
    margin: 40px auto;
    width: calc(1220px + 24px);
    height: auto;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    .contentItem {
      width: 287px;
      height: 299px;
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 24px;
      cursor: pointer;
      margin: 0 24px 24px 0;

      .imgItem {
        width: 287px;
        height: 161px;
        position: relative;

        .img {
          width: 287px;
          height: 161px;
          border-radius: 8px 8px 0 0;
        }

        .imgTips {
          width: 80px;
          height: 26px;
          background: linear-gradient(134deg, #5e6c77 0%, #222733 100%);
          border-radius: 8px 0 8px 0;
          text-align: center;
          line-height: 26px;
          color: #ffbe5d;
          font-size: 14px;
          font-family: Microsoft YaHei-Bold;
          font-weight: bold;
          font-style: italic;
          position: absolute;
          top: 0;
          left: 0;
          /* -webkit-background-clip: text; */
          /* -webkit-text-fill-color: transparent; */
        }

        .oneToOne {
          border-radius: 8px 0 8px 0;
          width: 77px;
          height: 24px;
          background: url('~@/assets/img/Home/oneToOne/one-to-one-tips.png');
          position: absolute;
          top: 0;
          left: 0;
          color: #ffffff;
          line-height: 24px;
          padding-left: 9px;
        }
      }

      .titleItem {
        margin: 12px auto 0 auto;
        width: 245px;
        height: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        color: #333333;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      .priceItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px 0 16px;

        .symbolOrprice {
          .symbol {
            font-size: 14px;
            color: #ff5e51;
          }

          .price {
            color: #ff5e51;
            font-size: 16px;
            font: bold;
            font-family: DIN-Bold;
          }
        }

        .courseNum {
          color: #999999;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular;
        }

        .more {
          display: flex;

          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }

      .line {
        height: 1px;
        width: 255px;
        background-color: #eeeeee;
        margin: 20px auto 17px auto;
      }

      .bottomTips {
        width: 100%;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        color: #b8681e;

        .topTips {

          // margin-bottom: 10px;
          .incon {
            display: inline;
            margin-right: 4px;
          }

          .tipsFirst {
            font-size: 12px;

            color: #b8681e;
            font-family: Microsoft YaHei-Regular;

            .courseNum {
              color: #999999;
            }
          }

          .tipsItem {
            font-size: 12px;
            color: #999999;
          }

          .verticalLine {
            width: 1px;
            height: 8px;
            background-color: #dddddd;
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
    }
  }

  .pagination {
    margin: auto;
  }
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

.img {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}

.xbk {
  img {
    width: 60px !important;
    height: 22px !important;
    display: inline !important;
    margin-right: 8px !important;
    position: relative;
    top: 5px;
  }
}
</style>
